export class APIConstants {
    static readonly HEADER_NM_CONTENT_TYP = 'Content-Type';
    static readonly HEADER_NM_X_AMZ_ACL = 'x-amz-acl';
    static readonly HEADER_VAL_APPLICATION_JSON = 'application/json; charset=utf-8';
    static readonly HEADER_VAL_APPLICATION_X_AMZ_JSON = 'application/x-amz-json-1.1';
    static readonly HEADER_NM_X_AMZ_TARGET = 'x-amz-target';
    static readonly HEADER_VAL_GET_ID = 'AWSCognitoIdentityService.GetId';
    static readonly HEADER_VAL_BUCKET_OWNER_FULL_CONTROL = 'bucket-owner-full-control';
    static readonly HEADER_VAL_GET_CRED_FOR_ID = 'AWSCognitoIdentityService.GetCredentialsForIdentity';
}
